@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-white;
}

@layer base {
  h1, h2, h3, h4, h5, h6, p, span, div {
    @apply text-white;
  }
}

@layer components {
  .glow {
    text-shadow: 0 0 10px rgba(0, 112, 192, 0.7);
  }
  
  .hover-glow {
    @apply transition-all duration-300;
  }
  
  .hover-glow:hover {
    box-shadow: 0 0 15px rgba(0, 112, 192, 0.7);
  }

  .btn {
    @apply px-4 py-2 rounded-lg font-medium transition-all duration-200 hover-glow;
  }

  .btn-primary {
    @apply bg-primary text-white hover:bg-primary/90;
  }

  .btn-secondary {
    @apply bg-secondary text-white hover:bg-secondary/90;
  }

  .btn-accent {
    @apply bg-accent text-white hover:bg-accent/90;
  }

  .bg-white, .bg-gray-100 {
    @apply text-black;
  }
  
  .bg-secondary {
    @apply text-black;
  }
}

.particle {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: rgba(0, 191, 255, 0.5);
  border-radius: 50%;
  animation: float 8s infinite;
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
}

.bg-hero {
  background-image: url(./assets/placeholder.svg);
}