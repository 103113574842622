.pageContainer {
  min-height: 100vh;
  background: linear-gradient(135deg, rgb(6 78 59) 0%, rgb(17 94 89) 50%, rgb(6 78 59) 100%);
}

.header {
  background: rgba(6, 78, 59, 0.95);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(16, 185, 129, 0.2);
}

.cardBase {
  background: linear-gradient(135deg, 
    rgba(6, 95, 70, 0.7) 0%,
    rgba(17, 94, 89, 0.6) 50%,
    rgba(6, 78, 59, 0.7) 100%
  );
  border: 1px solid rgba(16, 185, 129, 0.3);
  transition: all 0.3s ease;
}

.cardBase:hover {
  border-color: rgba(16, 185, 129, 0.5);
  box-shadow: 0 4px 12px rgba(16, 185, 129, 0.1);
}

.gradientText {
  background: linear-gradient(to right, rgb(167, 243, 208), rgb(52, 211, 153));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 600;
}

.primaryText {
  color: rgb(167, 243, 208);
}

.secondaryText {
  color: rgb(134, 239, 172);
}

.descriptionText {
  color: rgb(209, 250, 229);
  line-height: 1.6;
}

.tabsList {
  background: rgba(6, 78, 59, 0.5);
  border: 1px solid rgba(16, 185, 129, 0.3);
  border-radius: 0.5rem;
}

.tabTrigger {
  color: rgb(209, 250, 229);
  font-weight: 500;
  transition: all 0.2s ease;
}

.tabTrigger:hover {
  background: rgba(6, 95, 70, 0.5);
}

.tabTrigger[data-state="active"] {
  background: rgba(16, 185, 129, 0.9);
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.progressBar {
  height: 10px;
  background: rgba(6, 78, 59, 0.3);
  border-radius: 9999px;
  overflow: hidden;
  border: 1px solid rgba(16, 185, 129, 0.2);
}

.progressBarFill {
  background: linear-gradient(to right, rgb(16, 185, 129), rgb(45, 212, 191));
  transition: width 0.5s ease;
}

.badge {
  background: rgba(16, 185, 129, 0.2);
  color: rgb(209, 250, 229);
  border: 1px solid rgba(16, 185, 129, 0.4);
  font-weight: 500;
  padding: 0.25rem 0.75rem;
}

.badge:hover {
  background: rgba(16, 185, 129, 0.3);
  border-color: rgba(16, 185, 129, 0.5);
}

.imageOverlay {
  background: linear-gradient(to top, rgba(6, 78, 59, 0.8), transparent);
  opacity: 0.6;
}

.carouselButton {
  background: rgba(6, 78, 59, 0.8);
  border: 1px solid rgba(16, 185, 129, 0.5);
  color: rgb(167, 243, 208);
}

.carouselButton:hover {
  background: rgba(6, 95, 70, 0.9);
  border-color: rgba(16, 185, 129, 0.4);
}

.footer {
  background: rgba(6, 78, 59, 0.95);
  backdrop-filter: blur(8px);
  border-top: 1px solid rgba(16, 185, 129, 0.2);
}

.cardContent {
  background: rgba(6, 78, 59, 0.8);
  border-radius: 0.5rem;
  padding: 1rem;
} 