.container {
  min-height: 100vh;
  background: linear-gradient(135deg, rgb(0 89 153) 0%, rgb(0 112 192) 50%, rgb(0 89 153) 100%);
}

.heroSection {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  padding-top: 5rem;
}

.heroOverlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, rgba(0, 112, 192, 0.9), rgba(0, 70, 120, 0.8));
}

.title {
  color: rgb(255, 255, 255);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.subtitle {
  color: #53EC1B;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.countdownCard {
  background: rgba(0, 112, 192, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem;
  border-radius: 1rem;
  transition: transform 0.3s ease;
}

.countdownCard:hover {
  transform: translateY(-4px);
}

.countdownNumber {
  color: #53EC1B;
  font-size: 2.5rem;
  font-weight: bold;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.countdownLabel {
  color: #53EC1B;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.primaryButton {
  background: rgb(254, 91, 0);
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: none;
  text-align: center;
  display: inline-block;
}

.primaryButton:hover {
  background: rgba(254, 91, 0, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(254, 91, 0, 0.3);
}

.secondaryButton {
  background: #53EC1B;
  color: black;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  text-align: center;
  display: inline-block;
}

.secondaryButton:hover {
  background: rgba(83, 236, 27, 0.9);
  transform: translateY(-2px);
  border-color: rgba(0, 0, 0, 0.2);
}

.whiteButton {
    background: white;
    color: black;
    padding: 0.75rem 2rem;
    border-radius: 9999px;
    font-weight: 600;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    display: inline-block;
}
  
  .whiteButton:hover {
    background:  white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 112, 192, 0.2);
  }

.aboutSection {
  background: linear-gradient(to bottom, rgb(0, 112, 192), rgb(0, 70, 120));
  padding: 5rem 0;
}

.registerSection {
  background: linear-gradient(to bottom, rgb(0, 70, 120), rgb(0, 112, 192));
  padding: 5rem 0;
}

.parentsSection {
  background: linear-gradient(to bottom, rgb(0, 112, 192), rgb(0, 70, 120));
  padding: 5rem 0;
}

.sectionTitle {
  color: rgb(255, 255, 255);
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.sectionSubtitle {
  color: rgb(219, 234, 254);
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 3rem;
}

.featureCard {
  background: rgba(0, 112, 192, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 1rem;
  transition: all 0.3s ease;
}

.featureCard:hover {
  transform: translateY(-4px);
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 12px rgba(0, 112, 192, 0.2);
}

.featureTitle {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.featureText {
  color: rgb(219, 234, 254);
  line-height: 1.6;
}

.gradientText {
  background: linear-gradient(to right, rgb(219, 234, 254), rgb(255, 255, 255));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.badge {
  background: rgba(0, 112, 192, 0.2);
  color: rgb(219, 234, 254);
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.badge:hover {
  background: rgba(0, 112, 192, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
} 