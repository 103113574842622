.container {
  min-height: 100vh;
  background: linear-gradient(135deg, rgb(255 255 255) 0%, rgb(243 244 246) 50%, rgb(255 255 255) 100%);
  position: relative;
}

.header {
  background: rgba(255, 255, 255, 0.95);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(229, 231, 235, 0.5);
  position: sticky;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 40;
}

.title {
  color: rgb(17 24 39);
}

.subtitle {
  color: rgb(75 85 99);
}

.avatarContainer {
  background: linear-gradient(to bottom right, rgb(229 231 235), rgb(209 213 219));
  border-radius: 9999px;
}

.card {
  background: rgb(255 255 255);
  border: 1px solid rgb(229 231 235);
  transition: all 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transform: translateY(-2px);
}

.badge {
  background: rgb(243 244 246);
  color: rgb(75 85 99);
  border: 1px solid rgb(229 231 235);
  transition: all 0.2s ease;
}

.badge:hover {
  background: rgb(229 231 235);
}

.progressBar {
  background: rgb(243 244 246);
  border-radius: 9999px;
  overflow: hidden;
}

.progressBarFill {
  background: linear-gradient(to right, rgb(59 130 246), rgb(37 99 235));
  transition: width 0.5s ease;
}

.footer {
  background: rgb(249 250 251);
  border-top: 1px solid rgb(229 231 235);
}

.timelineContainer {
  position: relative;
}

.timelineContainer::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background: rgb(229 231 235);
}

.timelineEvent {
  position: relative;
  margin-bottom: 2rem;
}

.timelineMarker {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: rgb(59 130 246);
  border: 2px solid rgb(255 255 255);
} 