.container {
  min-height: 100vh;
  background: linear-gradient(135deg, rgb(255, 255, 255) 0%, rgb(243, 244, 246) 50%, rgb(255, 255, 255) 100%);
  padding-top: 5rem;
}

.mainTitle {
  font-size: 3.5rem;
  font-weight: bold;
  background: linear-gradient(to right, rgb(0, 112, 192), rgb(0, 191, 255));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 1rem;
}

.mainSubtitle {
  color: rgb(75, 85, 99);
  font-size: 1.25rem;
  line-height: 1.75;
  max-width: 800px;
  margin: 0 auto;
}

.infoCard {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 231, 235);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  padding: 2rem;
}

.infoCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 112, 192, 0.1);
}

.infoTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(0, 112, 192);
  margin-bottom: 1rem;
}

.infoText {
  color: rgb(75, 85, 99);
  line-height: 1.6;
}

.benefitsList {
  list-style-type: none;
  padding: 0;
  color: rgb(75, 85, 99);
}

.benefitsList li {
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.benefitsList li:before {
  content: "→";
  position: absolute;
  left: 0;
  color: rgb(0, 112, 192);
}

.timelineCard {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 231, 235);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.timelineTitle {
  font-size: 1.75rem;
  font-weight: bold;
  color: rgb(0, 112, 192);
  margin-bottom: 2rem;
  text-align: center;
}

.timeline {
  display: grid;
  gap: 1.5rem;
}

.timelineItem {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background: rgb(249, 250, 251);
  border-radius: 0.5rem;
  border: 1px solid rgb(229, 231, 235);
}

.timelineDate {
  font-weight: bold;
  color: rgb(0, 112, 192);
  min-width: 120px;
}

.timelineText {
  color: rgb(75, 85, 99);
}

.ctaSection {
  text-align: center;
  margin-top: 4rem;
  padding: 3rem;
  background: rgb(255, 255, 255);
  border-radius: 1rem;
  border: 1px solid rgb(229, 231, 235);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ctaTitle {
  font-size: 2rem;
  font-weight: bold;
  color: rgb(0, 112, 192);
  margin-bottom: 1rem;
}

.ctaText {
  color: rgb(75, 85, 99);
  margin-bottom: 2rem;
  font-size: 1.125rem;
  line-height: 1.6;
}

.ctaButton {
  background: rgb(0, 112, 192);
  color: white;
  padding: 1rem 2.5rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: none;
  font-size: 1.125rem;
}

.ctaButton:hover {
  background: rgb(0, 90, 160);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 112, 192, 0.2);
}

.quizCard {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 231, 235);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.quizQuestion {
  color: rgb(31, 41, 55);
  text-align: center;
  margin-bottom: 2rem;
}

.quizOption {
  background: rgb(249, 250, 251);
  color: rgb(75, 85, 99);
  transition: all 0.2s ease;
  border: 1px solid rgb(229, 231, 235);
}

.quizOption:hover {
  background: rgb(243, 244, 246);
  transform: translateX(4px);
  border-color: rgb(0, 112, 192);
}

.progressBar {
  height: 0.5rem;
  background: rgb(243, 244, 246);
  border-radius: 9999px;
  overflow: hidden;
}

.progressIndicator {
  background: linear-gradient(to right, rgb(0, 112, 192), rgb(0, 191, 255));
  height: 100%;
  transition: width 0.3s ease;
}

.resultCard {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(229, 231, 235);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  transition: all 0.3s ease;
}

.resultCard:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 112, 192, 0.1);
}

.resultCardTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(0, 112, 192);
  margin-bottom: 1rem;
}

.resultCardText {
  color: rgb(75, 85, 99);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.primaryButton {
    background: rgb(254, 91, 0);
    color: white;
    padding: 0.75rem 2rem;
    border-radius: 9999px;
    font-weight: 600;
    transition: all 0.3s ease;
    border: none;
    text-align: center;
    display: inline-block;
  }
  
  .primaryButton:hover {
    background: rgba(254, 91, 0, 0.9);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(254, 91, 0, 0.3);
  }
  
  .secondaryButton {
    background: #53EC1B;
    color: black;
    padding: 0.75rem 2rem;
    border-radius: 9999px;
    font-weight: 600;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    text-align: center;
    display: inline-block;
  }
  
  .secondaryButton:hover {
    background: rgba(83, 236, 27, 0.9);
    transform: translateY(-2px);
    border-color: rgba(0, 0, 0, 0.2);
  }
  
  .whiteButton {
      background: white;
      color: black;
      padding: 0.75rem 2rem;
      border-radius: 9999px;
      font-weight: 600;
      transition: all 0.3s ease;
      border: 1px solid rgba(0, 0, 0, 0.1);
      text-align: center;
      display: inline-block;
  }
    
    .whiteButton:hover {
      background:  white;
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 112, 192, 0.2);
    }
  
.modalOverlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.modalContent {
  background: rgb(255, 255, 255);
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  width: 100%;
  max-width: 900px;
  position: relative;
  overflow: hidden;
  max-height: 90vh;
  overflow-y: auto;
}

.modalClose {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 112, 192, 0.1);
  border: none;
  border-radius: 9999px;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: rgb(107, 114, 128);
  z-index: 10;
}

.modalClose:hover {
  background: rgba(0, 112, 192, 0.2);
  color: rgb(55, 65, 81);
}

.modalCta {
  text-align: center;
  margin-top: 2rem;
  padding: 2rem;
  background: rgb(249, 250, 251);
  border-radius: 1rem;
  border: 1px solid rgb(229, 231, 235);
}

.resultGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.resultCard {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.resultCardContent {
  flex: 1;
  padding: 1.5rem;
}

.quizContent {
  padding: 2rem;
  max-width: 600px;
  margin: 0 auto;
}

.resultsContent {
  padding: 2rem;
}

.resultTitle {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: bold;
  color: rgb(0, 112, 192);
}

.modalContent::-webkit-scrollbar {
  width: 8px;
}

.modalContent::-webkit-scrollbar-track {
  background: rgb(243, 244, 246);
  border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb {
  background: rgb(0, 112, 192);
  border-radius: 4px;
}

.modalContent::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 90, 160);
}
  