.container {
  min-height: 100vh;
  background: linear-gradient(135deg, rgb(0, 112, 192) 0%, rgb(0, 89, 153) 50%, rgb(0, 112, 192) 100%);
  padding-top: 5rem;
}

.title {
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.subtitle {
  color: #53EC1B;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.card {
  background: rgba(30, 41, 59, 0.8);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(59, 130, 246, 0.2);
  transition: all 0.3s ease;
}

.card:hover {
  border-color: rgba(59, 130, 246, 0.3);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
  transform: translateY(-2px);
}

.featureCard {
  background: rgba(30, 41, 59, 0.6);
  border: 1px solid rgba(59, 130, 246, 0.2);
  transition: all 0.3s ease;
}

.featureCard:hover {
  background: rgba(30, 41, 59, 0.8);
  border-color: rgba(59, 130, 246, 0.3);
}

.icon {
  color: #53EC1B;
}

.text {
  color: rgb(226 232 240);
}

.button {
  background: rgb(59 130 246);
  color: white;
  transition: all 0.3s ease;
}

.button:hover {
  background: rgb(37 99 235);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.2);
}

.agendaItem {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: rgba(30, 41, 59, 0.4);
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid rgba(59, 130, 246, 0.1);
}

.agendaTime {
  color: rgb(96 165 250);
  font-weight: 600;
}

.agendaText {
  color: rgb(226 232 240);
  margin-left: 0.5rem;
}

.heroSection {
  text-align: center;
  padding: 4rem 0;
  margin-bottom: 2rem;
}

.eventInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: white;
  font-weight: 600;
  font-size: 1.25rem;
}

.benefitsSection {
  margin: 4rem 0;
}

.benefitCard {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
  transition: all 0.3s ease;
}

.benefitCard:hover {
  transform: translateY(-4px);
  border-color: #53EC1B;
  box-shadow: 0 4px 12px rgba(83, 236, 27, 0.2);
}

.benefitIcon {
  color: #53EC1B;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto 1rem;
}

.benefitCard h3 {
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.benefitCard p {
  color: rgb(226 232 240);
}

.partnershipCta {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 2px solid #53EC1B;
  border-radius: 1rem;
  padding: 3rem 2rem;
  text-align: center;
  margin: 4rem 0;
}

.ctaTitle {
  font-size: 2.5rem;
  font-weight: bold;
  color: rgb(255 255 255);
  margin-bottom: 1.5rem;
}

.ctaText {
  color: rgb(226 232 240);
  font-size: 1.125rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  line-height: 1.7;
}

.ctaButtons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.primaryButton {
  background: #FE5B00;
  color: white;
  padding: 1rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: none;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.primaryButton:hover {
  background: rgba(254, 91, 0, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(254, 91, 0, 0.3);
}

.secondaryButton {
  background: #53EC1B;
  color: black;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.secondaryButton:hover {
  background: rgba(83, 236, 27, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(83, 236, 27, 0.3);
}

.contactSection {
  text-align: center;
  padding: 3rem 0;
}

.contactTitle {
  color: rgb(255 255 255);
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contactText {
  color: rgb(226 232 240);
}

.contactLink {
  color: rgb(96 165 250);
  text-decoration: underline;
  transition: color 0.2s ease;
}

.contactLink:hover {
  color: rgb(59 130 246);
} 


.primaryButton {
  background: rgb(254, 91, 0);
  color: white;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: none;
  text-align: center;
  display: inline-block;
}

.primaryButton:hover {
  background: rgba(254, 91, 0, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(254, 91, 0, 0.3);
}

.secondaryButton {
  background: #53EC1B;
  color: black;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  text-align: center;
  display: inline-block;
}

.secondaryButton:hover {
  background: rgba(83, 236, 27, 0.9);
  transform: translateY(-2px);
  border-color: rgba(0, 0, 0, 0.2);
}

.whiteButton {
    background: white;
    color: black;
    padding: 0.75rem 2rem;
    border-radius: 9999px;
    font-weight: 600;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    display: inline-block;
}
  
  .whiteButton:hover {
    background:  white;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 112, 192, 0.2);
  }

/* Add these new styles */
.agendaContainer {
  padding: 2rem;
}

.agendaCard {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  padding: 3rem;
  max-width: 900px;
  margin: 0 auto;
  border: 2px solid #0070C0;
}

.agendaHeader {
  text-align: center;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgb(229, 231, 235);
}

.agendaTitle {
  color: #0070C0;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.agendaSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: rgb(75, 85, 99);
  font-size: 1.125rem;
}

.agendaSection {
  margin-bottom: 3rem;
}

.sessionTitle {
  color: #0070C0;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  border-left: 4px solid #FE5B00;
}

.timelineContainer {
  padding-left: 2rem;
}

.timelineItem {
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.timelineItem::before {
  content: '';
  position: absolute;
  left: -2rem;
  top: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #53EC1B;
}

.timelineTime {
  min-width: 6rem;
  color: #FE5B00;
  font-weight: 600;
}

.timelineContent {
  flex: 1;
}

.timelineContent h4 {
  color: rgb(17, 24, 39);
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.timelineContent p {
  color: rgb(75, 85, 99);
  line-height: 1.5;
}

.agendaDivider {
  height: 1px;
  background: rgb(229, 231, 235);
  margin: 3rem 0;
}

/* Update icon color for agenda */
.icon {
  color: #53EC1B;
}
