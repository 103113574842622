.container {
  min-height: 100vh;
  background: linear-gradient(135deg, rgb(15 23 42) 0%, rgb(30 41 59) 50%, rgb(15 23 42) 100%);
}

.title {
  color: rgb(255 255 255);
  font-weight: bold;
}

.subtitle {
  color: rgb(203 213 225);
}

.card {
  background: rgba(30, 41, 59, 0.8);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border: 1px solid rgba(59, 130, 246, 0.2);
  padding: 1.5rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.card:hover {
  border-color: rgba(59, 130, 246, 0.3);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.1);
}

.downloadButton {
  width: 100%;
  background: rgba(59, 130, 246, 0.1);
  color: rgb(219 234 254);
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}

.downloadButton:hover {
  background: rgba(59, 130, 246, 0.2);
  transform: translateY(-1px);
}

.guidelineText {
  color: rgb(226 232 240);
  line-height: 1.5;
}

.icon {
  color: rgb(96 165 250);
}

.primaryButton {
  background: rgb(59 130 246);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.primaryButton:hover {
  background: rgb(37 99 235);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(59, 130, 246, 0.2);
}

.secondaryButton {
  background: rgba(59, 130, 246, 0.1);
  color: rgb(219 234 254);
  border: 1px solid rgba(59, 130, 246, 0.3);
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.secondaryButton:hover {
  background: rgba(59, 130, 246, 0.2);
  border-color: rgba(59, 130, 246, 0.4);
  transform: translateY(-1px);
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(255 255 255);
  margin-bottom: 1rem;
}

.divider {
  border-top: 1px solid rgba(59, 130, 246, 0.2);
  margin: 1.5rem 0;
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: rgb(219 234 254);
}

.cardGrid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
} 